import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    setFetchAttempted(true);
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
        if (decodedCity === "unknown") {
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData);
            })
            .catch(error => console.error('Fetch error on external API:', error));
        } else {
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase()));
          setCity(decodedCity);
          console.log(data);
        }
      })
      .catch(error => console.error('Fetch error on local API:', error));
  }, []);

  useEffect(() => {
    // Store the original body style
    const originalStyle = document.body.style.cssText;
  
    // Set the new background style
    document.body.style.backgroundImage = "url('https://i.ibb.co/94dykKW/bg.gif')";
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.minHeight = '100vh';
  
    // Reset to the original style on component unmount
    return () => {
      document.body.style.cssText = originalStyle;
    };
  }, []);

  const imageUrls = [
    'https://i.ibb.co/YjSjb0k/cropped-photo-2024-05-27-17-49-23.jpg',
    'https://i.ibb.co/2cx9x9z/cropped-photo-2024-05-27-17-49-26.jpg',
    'https://i.ibb.co/n1XTX58/cropped-photo-2024-05-27-17-49-28.jpg',
    'https://i.ibb.co/pPXdNjt/cropped-photo-2024-05-27-17-49-30.jpg',
    'https://i.ibb.co/xjH3rt8/cropped-photo-2024-05-27-17-49-32.jpg',
    'https://i.ibb.co/YjSjb0k/cropped-photo-2024-05-27-17-49-23.jpg',
    'https://i.ibb.co/2cx9x9z/cropped-photo-2024-05-27-17-49-26.jpg',
    'https://i.ibb.co/n1XTX58/cropped-photo-2024-05-27-17-49-28.jpg',
    'https://i.ibb.co/pPXdNjt/cropped-photo-2024-05-27-17-49-30.jpg',
    'https://i.ibb.co/xjH3rt8/cropped-photo-2024-05-27-17-49-32.jpg'
  ];

  return (
    <div className="container">
    <img 
      src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
      alt="Header Image"
      className="header-image"
    />
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={'2'}
      coverflowEffect={{
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
     }}
    autoplay={{ // Corrected from autoPlay to autoplay
      delay: 2000,
      disableOnInteraction: false,
    }}
    breakpoints={{
      // Screens smaller than 640px
      640: {
        slidesPerView: 2,
      },
      // Larger screens
      640: {
        slidesPerView: 3, // or whatever number you prefer
      },
    }}
    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
    className="swiper-container"
  >
    {imageUrls.map(url => (
      <SwiperSlide key={url} className="swiper-slide">
        <img src={url} alt="slide_image"/>
      </SwiperSlide>
    ))}
      <div className="slider-controler">
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>

    <div className='textContainer'>
      <img src='https://i.ibb.co/WsKkJRp/cropped-photo-2024-05-27-17-49-26-modified.png'/>
      <section>
          <div className='flexRow'>Lillian 🍭 <div className='buttonStyling'>Online Now</div></div>
          <div className='flexRow'>
            <span>
            Your favorite 18yo big tittied girl currently in {city}, {region}   {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
              />} 
          
              
            </span>
          </div>


      </section>
    </div>

    <a href="https://onlyfans.com/jellybellylillian/c25" id="customButton">
      Send me a message
    </a>

    <p className="exclusiveContent">
    <span>
    Exclusive Content🔥 80% OFF For 10 PEOPLE ONLY <br/><br/>
    Looking for content partners while im travelling, message me on Onlyfans if your close and want to shoot spicy content 👀
              </span>
    </p>
    <Analytics/>
  </div>
  );
}

export default App;
